<template>
  <div id="member-area-container">
    <MemberMenu />
    <div>
      <div class="container-fluid center-max-1320-px p-3">
        <h3 class="text-center">Please download VPN Client for your device:</h3>
        <div class="row flex-sm-nowrap justify-content-center">
          <div class="col-md-3 d-flex justify-content-center m-3">
            <a :href="`/api/v1/users/${userId}/client-download/WINDOWS`">
              <img src="/static/images/windows.png" class="image-fluid" />
            </a>
          </div>
          <div class="col-md-3 d-flex justify-content-center m-3">
            <a :href="`/api/v1/users/${userId}/client-download/MAC_OS`">
              <img src="/static/images/macos.png" class="image-fluid" />
            </a>
          </div>
        </div>
        <div class="row flex-sm-nowrap justify-content-center">
          <div class="col-md-3 d-flex justify-content-center m-3">
            <a :href="`/api/v1/users/${userId}/client-download/I_OS`">
              <img src="/static/images/ios.png" class="image-fluid" />
            </a>
          </div>
          <div class="col-md-3 d-flex justify-content-center m-3">
            <a :href="`/api/v1/users/${userId}/client-download/ANDROID`">
              <img src="/static/images/android.png" class="image-fluid" />
            </a>
          </div>
        </div>
        <div class="row flex-sm-nowrap justify-content-center p-3">
          <div class="col-md-6">
            <h3 class="text-center mb-4">How to use VPN Client:</h3>
            <ol class="step-list">
              <li>Donwload VPN Client using one of the links above.</li>
              <li>Install VPN Client on your device.</li>
              <li>
                Open VPN Client go to the 'Settings' and fill Username and Password received in the
                welcome email from us.
              </li>
              <li>
                On the main screen choose 'Server location' that you would like to connect to.
              </li>
              <li>Click/tap 'SWITCH ON' button.</li>
              <li>You're connected and ready to surf privately!!!</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MemberMenu from '@/app/components/MemberMenu';
import { useUser } from '@/app/use/user';

export default {
  name: 'VpnClient',
  components: { MemberMenu },
  inheritAttrs: false,
  setup() {
    const { userId } = useUser();

    return { userId };
  },
};
</script>
